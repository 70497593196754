body,
html {
  background: #292d3e;
  scroll-padding-top: 90px;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
  color: #68a59f;
}

:root {
  --dropdown-selected: #303244;
  --dropdown-border: #292d3e;
  --dropdown-background: #303244;
  --dropdown-color: #eee;
  --dropdown-hover-color: #89ddff;
  --selected-back: #303244;
  --selected-color: #89ddff;
  --item-back: #3a3f58;
  --item-color: #eee;
  --hover-back: #303244;
  --hover-color: #89ddff;
}

#root {
  padding-top: 71px;
  min-height: 100vh;
  box-sizing: border-box;
  color: #ccc;
}

#root > *:first-child {
  min-height: calc(100vh - 71px);
}

#footer {
  margin-top: 1px;
  box-sizing: border-box;
  padding: 16px 20px;
  height: 300px;
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.15);
  transition: background 0.5s;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 100vw;
}

button {
  cursor: pointer;
  background-color: #68a59f;
  color: #000;
  border: 1px solid #292d3e;
  font-weight: bold;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 2px 16px;
  font-weight: bold;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

button:not(:last-child) {
  margin-right: 0.6rem;
}

button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
